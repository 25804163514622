import React from 'react';
import PropTypes from 'prop-types';

function MetaTags(props) {
    const { baseURL, pageConfig, sectionConfig, server, siteConfig, staticAssets, title } = props;

    function renderAdditionalTags(sectionConfig, pageConfig, siteConfig, staticAssets) {
        const name =
            siteConfig.name ||
            'YES Network: New York Yankees, Brooklyn Nets and NYCFC news, stats, scores, highlights and more';
        const siteName = siteConfig.siteName || 'YESNetwork.com';
        const twitterUser = siteConfig.twitter_site || '@YESNetwork';
        const secureUrl = server.secure || 'https://www.yesnetwork.com';
        const canonicalUrl = `${secureUrl}${pageConfig.canonicalUrl || '/'}`;
        const description = pageConfig.description || siteConfig.description;
        const shareImage = pageConfig.shareImage || `${staticAssets}/assets/images/og/yes.jpg`;
        const ogTitle = pageConfig.ogTitle || title;
        const appView = pageConfig.appView || 'web';

        let robots;
        if (pageConfig.robotsContent) {
            robots = <meta name="robots" content={pageConfig.robotsContent} />;
        } else if (pageConfig.nofollow) {
            robots = <meta name="robots" content="noindex, nofollow" />;
        }

        return (
            <React.Fragment>
                <meta name="keywords" content={siteConfig.keywords} />
                <link rel="canonical" href={canonicalUrl} />
                <meta property="og:title" content={ogTitle} />
                <meta itemProp="name" content={name} />
                <meta property="og:description" content={description} />
                <meta name="description" content={description} />
                <meta itemProp="description" content={description} />
                <meta property="og:url" content={canonicalUrl} />
                <meta property="og:image" content={shareImage} />
                <meta property="og:type" content={siteConfig.type} />
                <meta property="og:site_name" content={siteName} />
                <meta property="fb:app_id" content={siteConfig.fb_app_id} />
                <meta itemProp="image" content={shareImage} />
                <meta name="twitter:card" value="summary" />
                <meta name="twitter:site" value={twitterUser} />
                <meta name="twitter:url" value={canonicalUrl} />
                <meta name="twitter:title" value={ogTitle} />
                <meta name="twitter:description" value={description} />
                <meta name="twitter:image" value={shareImage} />
                <meta name="app-view" value={appView} />
                {robots}
                {pageConfig.ampCanonical && <link rel="amphtml" href={pageConfig.ampCanonical} />}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <meta charSet="utf-8" />
            <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
            <meta httpEquiv="Cache-Control" content="no-cache" />
            <meta httpEquiv="Pragma" content="no-cache" />
            <meta httpEquiv="Expires" content="-1" />
            <meta name="header" content="true" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
            />
            <meta name="timezone" content="America/New_York" />
            <meta name="siteAssetsUri" content={`${baseURL}/dist`} />
            <link rel="shortcut icon" href={`${staticAssets}/assets/favicon.ico`} />
            {sectionConfig &&
                pageConfig &&
                siteConfig &&
                siteConfig.siteName &&
                renderAdditionalTags(sectionConfig, pageConfig, siteConfig, staticAssets)}
        </React.Fragment>
    );
}

MetaTags.propTypes = {
    baseURL: PropTypes.string,
    pageConfig: PropTypes.object.isRequired,
    sectionConfig: PropTypes.object.isRequired,
    siteConfig: PropTypes.object.isRequired,
    server: PropTypes.object.isRequired,
    staticAssets: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default MetaTags;
