import React from 'react';
import PropTypes from 'prop-types';
import { Image, Link } from '../../index';
import './styles.less';

const ImageOverlay = (props) => {
    const { className, imgCuts, text, alt, url } = props;

    return (
        <div className={`${className}`}>
            <Link href={url}>
                <Image lazyload={true} srcSet={imgCuts} alt={alt} />
                <span className={`${className}-text`}>{text}</span>
            </Link>
        </div>
    );
};

ImageOverlay.propTypes = {
    className: PropTypes.string,
    imgCuts: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
    alt: PropTypes.string
};

ImageOverlay.defaultProps = {
    className: 'image__overlay',
    text: '',
    url: '',
    alt: ''
};

export default ImageOverlay;
