import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';
import './styles.less';

const Image = (props) => {
    const { className, srcSet, lazyload, sizes, lazyloadOffset, alt, aspectRatio = 'raw', srcString } = props;

    // Set up the image itself
    let image = (
        <div className={`p-image ${className}`} data-aspect-ratio={aspectRatio}>
            <img
                src={srcString}
                srcSet={srcSet}
                data-srcset={srcSet}
                data-sizes={sizes || 'auto'}
                alt={alt}
                className={`lazyload`}
            />
        </div>
    );

    if (lazyload) {
        // TODO: setup placeholder to pass to LazyLoad
        image = (
            <LazyLoad once offset={[lazyloadOffset, 0]}>
                {image}
            </LazyLoad>
        );
    }
    return image;
};

Image.propTypes = {
    alt: PropTypes.string,
    aspectRatio: PropTypes.string,
    className: PropTypes.string,
    cuts: PropTypes.string,
    lazyload: PropTypes.bool,
    lazyloadOffset: PropTypes.number,
    sizes: PropTypes.array,
    srcSet: PropTypes.string,
    srcString: PropTypes.string
};

Image.defaultProps = {
    alt: undefined,
    aspectRatio: 'raw',
    className: 'yes-img',
    cuts: '',
    lazyload: true,
    lazyloadOffset: 200,
    sizes: [],
    srcSet: '',
    srcString: ''
};

export default Image;
