import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { Logo, isValidUrl } from '../../index';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    renderFooterItems() {
        const { baseURL, menuItems, sectionId } = this.props;
        const target = sectionId === 'thirdparty' ? '_top' : undefined;

        return (
            menuItems &&
            menuItems.map((item) => {
                const {
                    text,
                    properties: { link }
                } = item;
                const footerLink = isValidUrl(link) ? link : `${baseURL}${link}`;
                return (
                    <a key={text.toLowerCase().replace(/ +/g, '-')} href={footerLink} target={target}>
                        {text}
                    </a>
                );
            })
        );
    }

    render() {
        const { staticAssets } = this.props;

        return (
            <footer className="footer">
                <div className="footer__info">
                    <Logo
                        className="footer__info--logo"
                        src={`${staticAssets}/assets/images/light-on-dark/yes.svg`}
                        url="/"
                        alt="YES Logo"
                    />
                    <div className="footer__info--content">
                        <div className="footer__info--links">{this.renderFooterItems()}</div>
                        <div className="footer__info--appstore-buttons">
                            <Logo
                                className="p-appstore-button__link"
                                src={`${staticAssets}/assets/images/appstore/apple.svg`}
                                url="https://apps.apple.com/us/app/yes-network/id1543948562"
                                alt="apple appstore"
                                newWindow={true}
                            />
                            <Logo
                                className="p-playstore-button__link"
                                src={`${staticAssets}/assets/images/appstore/google.svg`}
                                url="https://play.google.com/store/apps/details?id=com.yesnetwork.mobile"
                                alt="google playstore"
                                newWindow={true}
                            />
                        </div>
                        <p className="footer__info--copyright">
                            &copy; {new Date().getFullYear()} YES NETWORK. ALL RIGHTS RESERVED. YESNetwork.com: Yankees
                            Entertainment &amp; Sports Network
                        </p>
                    </div>
                </div>
            </footer>
        );
    }
}

Footer.propTypes = {
    baseURL: PropTypes.string,
    menuItems: PropTypes.arrayOf(
        PropTypes.shape({
            text: PropTypes.string,
            properties: PropTypes.shape({
                link: PropTypes.string
            })
        })
    ).isRequired,
    sectionId: PropTypes.string,
    staticAssets: PropTypes.string
};

Footer.defaultProps = {
    baseURL: 'https://www.yesnetwork.com',
    menuItems: []
};

export default Footer;
