import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Ad } from '../../index';
import './styles.less';

class MigratedHtml extends Component {
    constructor(props) {
        super(props);
    }

    renderMigratedHtml() {
        const { className, migratedHtml } = this.props;
        const innerHtml = { __html: migratedHtml };
        return (
            <div
                className={classNames('migrated-html--content', `${className}--content`)}
                dangerouslySetInnerHTML={innerHtml}
            />
        );
    }

    render() {
        const { className } = this.props;
        const skinAdProps = {
            sectionName: 'info',
            outOfPage: true,
            targeting: { prdct: 'skins', pos: 1 },
            pageTargeting: { adunit: 'info', pageid: 'info' }
        };

        return (
            <div className={classNames('migrated-html', className)}>
                <div id="skin-ad">
                    <Ad {...skinAdProps} />
                </div>
                <div
                    className={classNames('section__container', 'migrated-html--container', `${className}--container`)}
                >
                    {this.renderMigratedHtml()}
                    <Ad className={'p-ad info-ad'} sectionName={'info'} targeting={{ pos: 1 }} slotSize={[300, 250]} />
                </div>
            </div>
        );
    }
}

MigratedHtml.propTypes = {
    className: PropTypes.string,
    migratedHtml: PropTypes.string.isRequired
};

MigratedHtml.defaultProps = {
    className: 'migrated-html',
    migratedHtml: ''
};

export default MigratedHtml;
