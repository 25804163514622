/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Caret, Dropdown, isValidUrl } from '../../index';

const MenuItem = ({ baseURL, className = '', isActive = 'false', onClick, navItem, hasDropdown }) => {
    if (navItem) {
        const { link } = navItem && navItem.properties;
        const menuLink = isValidUrl(link) ? link : `${baseURL}${link}`;
        return (
            <li key={`${navItem.text}--nav-item`} dropdown={`${hasDropdown}`} className={className}>
                <a className="nav__menu__items--label" href={menuLink}>
                    {navItem.text.toUpperCase()}
                </a>
                {hasDropdown && (
                    <Fragment>
                        <button onClick={() => onClick(navItem)} className={`nav__menu--button`} active={`${isActive}`}>
                            <Caret direction={isActive === true ? 'up' : 'down'} />
                        </button>
                        <Dropdown baseURL={baseURL} dropdownItems={navItem.menuItems} show={isActive} />
                    </Fragment>
                )}
            </li>
        );
    }

    return null;
};

export default MenuItem;

MenuItem.propTypes = {
    baseURL: PropTypes.string,
    className: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    onClick: PropTypes.func, // Toggle state of parent component
    navItem: PropTypes.object.isRequired,
    hasDropdown: PropTypes.bool
};

MenuItem.defaultProps = {};
