/* eslint-disable jsx-a11y/no-onchange */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import { Ad, Button, Image, Loader, generateCuts, parseCutsObj } from '../../index';

import './styles.less';

class SelectionIndex extends Component {
    constructor(props) {
        super(props);
        const { items, pagination } = props;
        const pageInfo = items.shift(); // first item contains information about page
        this.state = {
            items,
            pagination,
            loading: false,
            pageDepth: 1,
            pageInfo
        };

        this.handleItemClick = this.handleItemClick.bind(this);
        this.loadMoreItems = this.loadMoreItems.bind(this);
    }

    loadMoreItems() {
        const { pagination, pageDepth } = this.state;

        // make sure the pagination url is secure
        const nextUrl = pagination.nextUrl.replace(/^http:\/\//i, 'https://');
        this.setState({ loading: true }, () => {
            fetch(nextUrl)
                .then((res) => res.json())
                .then((result) => {
                    this.setState({
                        pagination: result.pagination,
                        pageDepth: pageDepth + 1,
                        items: [...this.state.items, ...result.items],
                        loading: false
                    });
                });
        });
        // Track this action
        window.dataLayerEvent &&
            window.dataLayerEvent({
                eventName: 'load more items',
                pageDepth: pageDepth + 1
            });
    }

    handleItemClick(targetUrl) {
        // Must return a function in order to pass param to onClick
        return function({ pageDepth } = this.state) {
            window.dataLayerEvent &&
                window.dataLayerEvent({
                    eventName: 'item click',
                    pageDepth,
                    targetUrl
                });
        };
    }

    renderItems() {
        const { items } = this.state;

        if (!items.length) {
            return <div className="selection-index__item--empty">There are no items to display.</div>;
        }

        const insertAd = (el, i) => {
            if (i !== items.length - 1 && (i + 1) % 6 === 0) {
                return [el, 'ad'];
            }
            return [el];
        };
        // Insert ads into the array and then flatten
        const itemsAndAds = [].concat.apply(
            [],
            items.map((item, idx) => insertAd(item, idx))
        );

        let adPos = 1;

        return itemsAndAds.map((item, idx) => {
            if (item === 'ad') {
                adPos++;
                return (
                    <div className="selection-index__ad" key={`ad-${idx}`}>
                        <div className="selection-index__item">
                            <Ad
                                className={'p-ad ad-responsive-slot selection-banner'}
                                sectionName="info"
                                sizeMapping={[
                                    { viewport: [0, 0], slot: [320, 50] },
                                    { viewport: [768, 0], slot: [728, 90] }
                                ]}
                                targeting={{ pos: adPos }}
                            />
                        </div>
                    </div>
                );
            }

            const { lastUpdatedDate, fields = {}, slug, thumbnail = {} } = item;
            const srcSetSquare = generateCuts(thumbnail.templateUrl, 'jpg', '1:1');
            const srcSetRect = generateCuts(thumbnail.templateUrl, 'jpg', '16:9');

            return (
                // Set index of item to be used as scroll watcher
                <div className={classNames('selection-index__item--content')} key={slug}>
                    <a
                        href={fields.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.handleItemClick(fields.url)}
                    >
                        <div className="selection-index__item">
                            <div className="selection-index__item--meta">
                                <div className="selection-index__item--headline">{fields.altHeadline}</div>
                                {fields.blurb && (
                                    <div className="selection-index__item--subheadline">{fields.blurb}</div>
                                )}
                                <div className="selection-index__item--date">
                                    {moment(lastUpdatedDate).format('MMMM D, YYYY')}
                                </div>
                            </div>
                            <div className="selection-index__item--image">
                                <Image
                                    className="selection-index__item--thumbnail-square"
                                    srcSet={parseCutsObj(srcSetSquare)}
                                    alt={thumbnail.title}
                                    title={thumbnail.title}
                                />
                                <Image
                                    className="selection-index__item--thumbnail-rect"
                                    srcSet={parseCutsObj(srcSetRect)}
                                    alt={thumbnail.title}
                                    title={thumbnail.title}
                                />
                            </div>
                        </div>
                    </a>
                </div>
            );
        });
    }

    render() {
        const { loading, pagination, pageInfo } = this.state;
        const { fields, thumbnail } = pageInfo;
        const skinAdProps = {
            sectionName: 'info',
            outOfPage: true,
            targeting: { prdct: 'skins', pos: 1 },
            pageTargeting: { adunit: 'info', pageid: 'info' }
        };

        let headerImageSrc;
        if (thumbnail) {
            headerImageSrc = thumbnail.templateUrl.replace(
                /http:|{CloudinaryStorageCloudName}|{formatInstructions}/gi,
                'q_100'
            );
        }

        return (
            <div className="selection-container">
                <div className="body-wrapper">
                    <div className="selection-index__header">
                        {thumbnail && (
                            <Image
                                className="selection-index__header--image"
                                srcString={headerImageSrc}
                                alt={thumbnail.title}
                                title={thumbnail.title}
                            />
                        )}
                        <h2 className="selection-index__header--content">{fields.altHeadline}</h2>
                    </div>
                    <div className="selection-index__item-wrapper">{this.renderItems()}</div>
                    {pagination && pagination.nextUrl && (
                        <div className="selection-index__load-more-wrapper">
                            {loading && <Loader color="#5d5d67" />}
                            {!loading && (
                                <Button
                                    text="Load More"
                                    size="lg"
                                    variant="secondary"
                                    centered
                                    onClick={this.loadMoreItems}
                                />
                            )}
                        </div>
                    )}
                </div>
                <div id="skin-ad">
                    <Ad {...skinAdProps} />
                </div>
            </div>
        );
    }
}

SelectionIndex.propTypes = {
    items: PropTypes.array,
    pagination: PropTypes.object
};

SelectionIndex.defaultProps = {
    items: [],
    pagination: {}
};

export default SelectionIndex;
