import styled from 'styled-components';

const bgColor = '#ececec';
const skinAdStyles = 'position: absolute; width: 1px; height: 1px; top: 0; left: 0;';

const getAdSize = (slotSize = [], sizeMapping = [], sectionName) => {
    if (sectionName === 'thirdparty') {
        return 'height: auto; width: auto';
    }

    if (slotSize && slotSize.length > 1) {
        return `height: ${slotSize[1]}px; width: ${slotSize[0]}px;`;
    }

    return sizeMapping
        .map((mapping) => {
            const { slot = [], viewport = [] } = mapping;
            return viewport[0] > 0 // 0 is default viewport width
                ? `@media screen and (min-width: ${viewport[0]}px) { height: ${slot[1]}px; width: ${slot[0]}px; }`
                : `height: ${slot[1]}px; width: ${slot[0]}px;`;
        })
        .join('\n');
};

export const AdWrapper = styled.div`
    line-height: 0;
    display: block;
    margin: 0 auto;
    text-align: center;
    max-width: 100%;
    background-color: ${bgColor};
    ${({ slotSize, sizeMapping, sectionName }) => getAdSize(slotSize, sizeMapping, sectionName)}
    ${({ isSkinAd }) => (isSkinAd ? skinAdStyles : '')}
`;
