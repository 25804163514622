const Events = {
    READY: 'ready',
    RENDER: 'render',
    SLOT_RENDER_ENDED: 'slotRenderEnded',
    IMPRESSION_VIEWABLE: 'impressionViewable',
    SLOT_VISIBILITY_CHANGED: 'slotVisibilityChanged',
    SLOT_LOADED: 'slotOnload'
};

export default Events;
