import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Logo } from '../../index';
import './styles.less';

class GameDetail extends Component {
    render() {
        const { homeTeam, awayTeam } = this.props.gameInfo;

        if (homeTeam && awayTeam) {
            return (
                <div className="game__detail">
                    <div className="game__detail--teams">
                        <Logo
                            className="game__detail--teams-home"
                            src={homeTeam.src}
                            url={homeTeam.url}
                            alt={homeTeam.alt}
                        />
                        <span className="game__detail--teams-at">@</span>
                        <Logo
                            className="game__detail--teams-away"
                            src={awayTeam.src}
                            url={awayTeam.url}
                            alt={awayTeam.alt}
                        />
                    </div>
                    <div className="game__detail--info">
                        <div className="game__detail--info-label">{awayTeam.label}</div>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }
}

GameDetail.propTypes = {
    gameInfo: PropTypes.object
};

GameDetail.defaultProps = {
    gameInfo: {
        awayTeam: {
            label: '',
            src: '',
            url: '',
            alt: ''
        },
        homeTeam: {
            label: '',
            src: '',
            url: '',
            alt: ''
        }
    }
};

export default GameDetail;
