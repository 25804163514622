import React from 'react';
import { AdWrapper } from './Ad.style';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.less';
import { Bling as GPT } from '@yes/react-gpt';

const BASE_PATH = '/2605/yesnetwork.com';

const Ad = (props) => {
    const { className, sectionName, pageName, targeting, pageTargeting, outOfPage } = props;
    let sectionId = sectionName;

    // Ad ops expects homepage as ad unit ID
    if (sectionId === 'home') {
        sectionId = 'homepage';
    }

    if (pageName === 'personalities') {
        sectionId = pageName;
    }

    const adUnitPath = `${BASE_PATH}/${sectionId}`;

    return (
        <AdWrapper
            {...props}
            data-adunit={adUnitPath}
            data-targeting={JSON.stringify(targeting)}
            data-page-targeting={JSON.stringify(pageTargeting)}
            className={classNames(className, {
                'ad-outofpage': outOfPage
            })}
        >
            <GPT adUnitPath={adUnitPath} {...props} pageTargeting={pageTargeting} targeting={targeting} />
        </AdWrapper>
    );
};

Ad.propTypes = {
    className: PropTypes.string,
    companionAdService: PropTypes.bool,
    outOfPage: PropTypes.bool, //interstitial/prestitials
    pageTargeting: PropTypes.object,
    renderWhenViewable: PropTypes.bool, // Lazy loading; is default
    sectionName: PropTypes.string,
    pageName: PropTypes.string,
    sizeMapping: PropTypes.arrayOf(
        PropTypes.shape({
            viewport: PropTypes.array, // [vw, vh]
            slot: PropTypes.array // [width, height]
        })
    ),
    slotSize: PropTypes.arrayOf(PropTypes.number), // [w, h]
    targeting: PropTypes.object,
    isSkinAd: PropTypes.bool
};

Ad.defaultProps = {
    companionAdService: false,
    outOfPage: false,
    renderWhenViewable: true,
    className: 'p-ad ad-responsive-slot'
};

export default Ad;
