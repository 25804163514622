import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from '../Icon';

import './styles.less';

const conditionalRenderLink = (
    url,
    useLinkAsBtn,
    openInNewTab,
    pbuttonbuttonAttributes,
    ariaLabel,
    text,
    icon,
    childRender
) => {
    if (url) {
        return (
            <a
                href={url}
                aria-label={ariaLabel}
                target={openInNewTab ? '_blank' : '_self'}
                rel={openInNewTab ? 'noopener noreferrer' : null}
                className={classNames({ 'p-button__link': true, 'p-tfl': false })}
            >
                {childRender()}
            </a>
        );
    } else {
        return childRender();
    }
};

const Button = (props) => {
    const { icon, onClick, ariaLabel = null, url = null, useLinkAsBtn = false } = props;

    const pbuttonbuttonAttributes = {
        className: classNames({
            'p-button__button': true,
            [`p-button__button--${props.size}`]: props.size,
            [`p-button__button--${props.variant}`]: props.variant,
            [`p-button__button--linkenabled`]: useLinkAsBtn
        }),
        disabled: props.disabled
    };

    if (onClick) {
        pbuttonbuttonAttributes.onClick = onClick;
    }

    const pbuttonAttributes = {
        className: classNames({
            'p-button': true,
            [`p-button--${props.size}`]: props.size,
            'p-button--centered': props.centered,
            [props.className]: props.className
        }),
        'data-content-id': props.contentId
    };

    if (props.data) {
        Object.keys(props.data).forEach((key) => {
            pbuttonbuttonAttributes[`data-${key}`] = props.data[key];
        });
    }

    if (!useLinkAsBtn) {
        return (
            <div {...pbuttonAttributes}>
                {conditionalRenderLink(
                    props.url,
                    useLinkAsBtn,
                    props.openInNewTab,
                    pbuttonbuttonAttributes,
                    ariaLabel,
                    props.text,
                    icon,
                    () => {
                        return (
                            <button {...pbuttonbuttonAttributes} aria-label={ariaLabel}>
                                {icon ? (
                                    <span className="p-button__icon">
                                        <Icon icon={icon} />
                                    </span>
                                ) : null}
                                {props.text ? <span className="p-button__text">{props.text}</span> : null}
                            </button>
                        );
                    }
                )}
            </div>
        );
    }

    return (
        <div {...pbuttonAttributes}>
            {conditionalRenderLink(
                props.url,
                useLinkAsBtn,
                props.openInNewTab,
                pbuttonbuttonAttributes,
                ariaLabel,
                props.text,
                icon,
                () => {
                    return (
                        <span {...pbuttonbuttonAttributes} aria-label={ariaLabel}>
                            {icon ? (
                                <span className="p-button__icon">
                                    <Icon icon={icon} />
                                </span>
                            ) : null}
                            {props.text ? <span className="p-button__text">{props.text}</span> : null}
                        </span>
                    );
                }
            )}
        </div>
    );
};

Button.propTypes = {
    centered: PropTypes.bool,
    text: PropTypes.string,
    icon: PropTypes.string, //String. See icon pattern
    size: PropTypes.oneOf(['md', 'sm', 'lg', 'full-width']),
    url: PropTypes.string,
    className: PropTypes.string, //String. Valid HTML class attribute
    variant: PropTypes.oneOf(['primary', 'secondary', 'watch-live']).isRequired,
    openInNewTab: PropTypes.bool,
    disabled: PropTypes.bool,
    data: PropTypes.shape({}),
    contentId: PropTypes.string,
    useLinkAsBtn: PropTypes.bool,
    ariaLabel: PropTypes.string,
    onClick: PropTypes.func // Optional click handler for button
};

Button.defaultProps = {
    variant: 'primary'
};

export default Button;
