import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import './styles.less';
import { BaseModule, Image, generateCuts, parseCutsObj, processNewsList } from '@yes/components';

const MAX_STORIES_COUNT = 3;

class MoreStoriesModule extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            newsList: props.newsList
        };

        this.renderList = this.renderList.bind(this);
        this.capitalize = this.capitalize.bind(this);
    }

    capitalize(string = '', allCaps = false) {
        if (allCaps) {
            return string.toUpperCase();
        }
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    componentDidMount() {
        const { cmsDapi, isScrapePage, teamContext } = this.props;
        const fullTeamName = teamContext && teamContext.slug;
        if (isScrapePage) {
            fetch(`${cmsDapi}/en-us/stories?tags.slug=${fullTeamName || 'yes-network'}&$limit=5`)
                .then((res) => res.json())
                .then(
                    (result) => {
                        const newsList = processNewsList(result, fullTeamName || 'yes-network', 'scrape');
                        this.setState({
                            newsList
                        });
                    },
                    (error) => {
                        console.error(error);
                        this.setState({
                            newsList: []
                        });
                    }
                );
        }
    }

    renderList(newsList, href) {
        return newsList.slice(0, MAX_STORIES_COUNT).map((item) => {
            const { slug, title, contentDate, thumbnail = {} } = item;
            const srcSetSquare = generateCuts(thumbnail.templateUrl, 'jpg', '1:1');
            const srcSetRect = generateCuts(thumbnail.templateUrl, 'jpg', '16:9');

            return (
                <a className="module__item" href={`${href}/${slug}`} key={slug}>
                    <div className="module__item--content">
                        <span className="module__item--title">{title}</span>
                        <span className="module__item--subtitle">{moment(contentDate).format('MMMM D, YYYY')}</span>
                    </div>
                    <Image
                        className="module__item--thumbnail-square"
                        srcSet={parseCutsObj(srcSetSquare)}
                        alt={thumbnail.title}
                        title={thumbnail.title}
                    />
                    <Image
                        className="module__item--thumbnail-rect"
                        srcSet={parseCutsObj(srcSetRect)}
                        alt={thumbnail.title}
                        title={thumbnail.title}
                    />
                </a>
            );
        });
    }

    render() {
        const { teamContext, isScrapePage, siteURL } = this.props;
        const { newsList } = this.state;
        if (!newsList.length) {
            return null;
        }
        const baseUrl = isScrapePage ? siteURL : '';
        const href = `${baseUrl}${teamContext.path || ''}/news`;

        return (
            <BaseModule
                extraClass="module__more-stories"
                headerContent={<h5 className="module__header--title">More Stories</h5>}
                bodyContent={<div className="module__item-container">{this.renderList(newsList, href)}</div>}
                footerContent={
                    <a href={href} className="module__item--title">
                        More{' '}
                        {teamContext.shorthand
                            ? this.capitalize(
                                  teamContext.shorthand,
                                  teamContext.shorthand === 'nycfc' || teamContext.shorthand === 'nfl'
                              )
                            : 'YES Network'}{' '}
                        News &rarr;
                    </a>
                }
            />
        );
    }
}

MoreStoriesModule.propTypes = {
    newsList: PropTypes.array,
    teamContext: PropTypes.object,
    isScrapePage: PropTypes.bool,
    cmsDapi: PropTypes.string,
    siteURL: PropTypes.string
};

MoreStoriesModule.defaultProps = {
    newsList: [],
    teamContext: {},
    isScrapePage: false,
    cmsDapi: 'https://dapi.cms.opprops.mlbinfra.com/v2/content'
};

export default MoreStoriesModule;
