import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { PromoBar, NavBar, Ad } from '../../index';

class Header extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const {
            baseURL,
            className,
            cmsDapi,
            cmsVsm,
            env,
            todayOnYesData,
            nextGamesData,
            tabs,
            hideNavigation,
            pageId,
            socialIcons,
            staticAssets,
            sectionId,
            watchLive
        } = this.props;

        return (
            <div className={className}>
                <Ad
                    sectionName={sectionId}
                    pageName={pageId}
                    sizeMapping={[
                        { viewport: [0, 0], slot: [320, 50] },
                        { viewport: [768, 0], slot: [728, 90] }
                    ]}
                />
                <PromoBar
                    sectionId={sectionId}
                    pageId={pageId}
                    baseURL={baseURL}
                    cmsDapi={cmsDapi}
                    todayOnYesData={todayOnYesData}
                    nextGamesData={nextGamesData}
                    className={className}
                />
                {!hideNavigation && (
                    <NavBar
                        baseURL={baseURL}
                        className={'nav'}
                        env={env}
                        logo={`${staticAssets}/assets/images/dark-on-light/yes.svg`}
                        sectionId={sectionId}
                        socialIcons={socialIcons}
                        isScrapePage={pageId === 'scrape'}
                        tabs={tabs}
                        watchLive={watchLive}
                        cmsVsm={cmsVsm}
                    />
                )}
            </div>
        );
    }
}

Header.propTypes = {
    className: PropTypes.string,
    env: PropTypes.string,
    todayOnYesData: PropTypes.object,
    nextGamesData: PropTypes.object,
    tabs: PropTypes.array,
    socialIcons: PropTypes.array,
    hideNavigation: PropTypes.bool,
    staticAssets: PropTypes.string,
    sectionId: PropTypes.string,
    pageId: PropTypes.string,
    baseURL: PropTypes.string,
    cmsDapi: PropTypes.string,
    cmsVsm: PropTypes.string,
    watchLive: PropTypes.bool
};

Header.defaultProps = {
    className: 'yes__header',
    env: 'production',
    hideNavigation: false,
    baseURL: 'https://www.yesnetwork.com',
    cmsDapi: 'https://dapi.cms.opprops.mlbinfra.com/v2/content',
    cmsVsm: 'https://feen.cms.opprops.mlbinfra.com/cms/api/public',
    watchLive: false
};

export default Header;
