import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import icons from './icons'; // eslint-disable-line import/no-extraneous-dependencies

// import './styles.less';

let iconCount = 0;

function findIcon(name, iconsObj = icons) {
    const icon = iconsObj.filter((obj) => obj.id === name);
    if (icon.length > 1) {
        throw new Error('More that one icons was found with same name');
    }
    const iconObj = icon.length === 0 ? false : icon[0];
    return iconObj;
}

function buildSvg(iconData) {
    const svgElements =
        iconData &&
        iconData.map((prop, index) => {
            const key = index;
            if (prop.name === 'path') {
                return <path {...prop.attrs} key={`path-${key}`} />;
            } else if (prop.name === 'circle') {
                return <circle {...prop.attrs} key={`circle-${key}`} />;
            } else if (prop.name === 'rect') {
                return <rect {...prop.attrs} key={`rect-${key}`} />;
            } else if (prop.name === 'ellipse') {
                return <ellipse {...prop.attrs} key={`ellipse-${key}`} />;
            } else if (prop.name === 'g') {
                return (
                    <g {...prop.attrs} key={`g-${key}`}>
                        {buildSvg(prop.childs)}
                    </g>
                );
            } else {
                return null;
            }
        });

    return svgElements;
}

const Icon = (props) => {
    let sizeNm = 16;
    switch (props.size) {
        case 'sm':
            sizeNm = 12;
            break;
        case 'md':
            sizeNm = 16;
            break;
        case 'lg':
            sizeNm = 24;
            break;
    }

    const icon = findIcon(props.icon);
    const content = buildSvg(icon.childs);

    const iconProps = {
        height: sizeNm,
        width: sizeNm,
        name: props.name,
        viewBox: icon.attrs && icon.attrs.viewBox,
        role: 'img',
        'aria-labelledby': `title-${icon.title}-${iconCount} desc-${icon.title}-${iconCount}`
    };

    const iconTitleProps = {
        id: `title-${icon.title}-${iconCount}`
    };

    const iconDescProps = {
        id: `desc-${icon.title}-${iconCount}`
    };

    iconCount++;

    return (
        <svg
            {...iconProps}
            className={classNames({
                'p-icon': true,
                [`p-icon--${props.icon}`]: true,
                [`p-icon--${props.size}`]: props.size,
                [props.className]: props.className
            })}
            style={props.style}
        >
            <title {...iconTitleProps}>{icon.title}</title>
            <desc {...iconDescProps}>{icon.title}</desc>
            {content}
        </svg>
    );
};

Icon.propTypes = {
    icon: PropTypes.string.isRequired, //String. See icon pattern.
    className: PropTypes.string, //String. Valid HTML class attribute
    size: PropTypes.oneOf(['md', 'sm', 'lg']),
    name: PropTypes.string,
    // eslint-disable-next-line
    style: PropTypes.object //Passes through to svg style prop.
};

Icon.defaultProps = {
    className: undefined,
    size: undefined,
    name: undefined
};

export default Icon;
