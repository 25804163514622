import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../index';

const Blurb = ({ className, blurb, relatedItems }) => {
    const renderRelatedItems = (relatedItems) => {
        return (
            <ul className={`${className}--list`}>
                {relatedItems.map((item, key) => {
                    return (
                        <li className={`${className}--text`} key={key}>
                            <Link href={item.url} target={item.isPopup} text={item.description} />
                        </li>
                    );
                })}
            </ul>
        );
    };

    return relatedItems ? renderRelatedItems(relatedItems) : <div className={`${className}--text`}>{blurb}</div>;
};

export default Blurb;

Blurb.propTypes = {
    className: PropTypes.string,
    blurb: PropTypes.string,
    relatedItems: PropTypes.arrayOf(
        PropTypes.shape({
            description: PropTypes.string,
            url: PropTypes.string,
            isPopup: PropTypes.bool
        })
    )
};

Blurb.defaultProps = {};
