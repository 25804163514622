import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const BannerText = ({ className = 'banner', headline, blurb }) => {
    return (
        <div className={className}>
            <div className={`${className}--headline`}>{headline}</div>
            <div className={`${className}--blurb`}>{blurb}</div>
        </div>
    );
};

export default BannerText;

BannerText.propTypes = {
    className: PropTypes.string,
    headline: PropTypes.string,
    blurb: PropTypes.string
};

BannerText.defaultProps = {};
