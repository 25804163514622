import React, { useEffect, useState } from 'react';
import { processPromoBarData } from '@yes/components';
import styled from 'styled-components';
import './styles.less';
import useMedia from '@mlb/react-patterns/dist/utils/hooks/useMedia/useMedia';

const LogoSpacer = styled.div`
    @media (min-width: 1024px) {
        width: 150px;
        height: 100%;
    }
`;

const PromoItemsContainer = styled.div`
    display: flex;
`;

const PromoItem = styled.a`
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    @media (min-width: 768px) {
        padding: 0 1rem;
    }
`;

const PromoBarLogoContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 0.5rem;
`;

const PromoBarLogo = styled.img`
    height: 20px;
    width: 20px;
    padding-right: 0px;
    padding-bottom: 0.25rem;
`;

const PromoBarText = styled.div`
    font-size: 13px;
    text-overflow: ellipsis;
    white-space: nowrap;
`;

const Spacer = styled.div`
    @media (max-width: 767px) {
        width: 1rem;
        margin-right: 0.5rem;
    }
    @media (min-width: 768px) {
        width: 4rem;
        margin-right: 2rem;
    }
    height: 65%;
    border-right: 1px solid #363a65;
`;

const RenderPromoItems = ({ data = [] }) => {
    const mobileWidth = useMedia({ maxWidth: '767px' });
    return (
        <PromoItemsContainer>
            {data.map((d) => {
                let { title, awayLogo, homeLogo, url, mobileUrl, isPopup = false } = d;
                return (
                    <PromoItem href={mobileWidth ? mobileUrl : url} target={isPopup ? '_blank' : ''}>
                        <PromoBarLogoContainer>
                            {homeLogo && <PromoBarLogo src={homeLogo} />}
                            {awayLogo && <PromoBarLogo src={awayLogo} />}
                        </PromoBarLogoContainer>
                        <div className="promo__bar--game">
                            <PromoBarText
                                className="promo__bar--primary-label-detail"
                                dangerouslySetInnerHTML={{
                                    __html: title
                                }}
                            />
                        </div>
                    </PromoItem>
                );
            })}
        </PromoItemsContainer>
    );
};

const PromoBar = ({ className, todayOnYesData, nextGamesData }) => {
    const [promoDetails, setPromoDetails] = useState({
        todayOnYes: [],
        nextGames: []
    });
    const smallDisplay = useMedia({ maxWidth: '767px' });
    const mediumDisplay = useMedia({ minWidth: '768px', maxWidth: '1023px' });

    useEffect(() => {
        setPromoDetails(processPromoBarData(todayOnYesData, nextGamesData));
    }, [todayOnYesData, nextGamesData]);

    const { todayOnYes, nextGames } = promoDetails;
    let nextGamesSliced = nextGames;
    if (smallDisplay) {
        nextGamesSliced = nextGames.slice(0, 1);
    } else if (mediumDisplay) {
        nextGamesSliced = nextGames.slice(0, 2);
    }

    return (
        <div className={`promo__bar ${className}`}>
            <div className="promo__bar--container">
                <LogoSpacer />
                <PromoBarText className="promo__bar--primary-label-note">Today on YES</PromoBarText>
                <RenderPromoItems data={todayOnYes} />
                <Spacer />
                {!smallDisplay && (
                    <>
                        <PromoBarText className="promo__bar--primary-label-note">Next Games on YES</PromoBarText>
                    </>
                )}
                <RenderPromoItems data={nextGamesSliced} />
            </div>
        </div>
    );
};

export default PromoBar;
