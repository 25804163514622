import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../index';
import './styles.less';

class RelatedItems extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { className, relatedItems } = this.props;
        return (
            <div className={className}>
                {relatedItems.map((relatedItem, key) => {
                    return (
                        <React.Fragment key={key}>
                            <div className={'divider'} />
                            <Link
                                className={`${className}--related-link`}
                                href={relatedItem.url}
                                target={relatedItem.isPopup ? '_blank' : undefined}
                                text={relatedItem.description}
                            />
                        </React.Fragment>
                    );
                })}
            </div>
        );
    }
}

RelatedItems.propTypes = {
    className: PropTypes.string,
    relatedItems: PropTypes.array
};

RelatedItems.defaultProps = {
    PropTypes: 'related-items',
    relatedItems: []
};

export default RelatedItems;
