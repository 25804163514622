import React from 'react';
import PropTypes from 'prop-types';
import { ImageOverlay } from '../../index';
import './styles.less';

const MoreContent = (props) => {
    const { className, moreItems, title } = props;

    return (
        <div className={`${className}`}>
            <div className={`${className}-title`}>{title}</div>
            <div className={`${className}-row`}>
                {moreItems.map((moreItem, key) => {
                    return <ImageOverlay className={`${className}-more-item`} {...moreItem} key={key} />;
                })}
            </div>
        </div>
    );
};

MoreContent.propTypes = {
    className: PropTypes.string,
    moreItems: PropTypes.array,
    title: PropTypes.string
};

MoreContent.defaultProps = {
    className: 'more__content',
    moreItems: [],
    title: ''
};

export default MoreContent;
