import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

// import './styles.less';

const HzRule = (props) => {
    return (
        <hr
            className={classNames({
                'p-horizontal-rule': true,
                [`p-horizontal-rule--${props.height}`]: props.height,
                [`p-horizontal-rule--margin-${props.margin}`]: props.margin
            })}
        />
    );
};

HzRule.propTypes = {
    height: PropTypes.oneOf(['short', 'tall']),
    margin: PropTypes.oneOf(['bottom-only', 'top-only'])
};

HzRule.defaultProps = {
    height: undefined,
    margin: undefined
};

export default HzRule;
