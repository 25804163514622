import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Logo } from '../../index';
import './styles.less';

class TeamBar extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    // The team name is bolded, so need to split string
    renderDisplayName(className, teamName) {
        let city = '';
        let mascot = '';

        if (teamName) {
            const lastIndex = teamName.lastIndexOf(' ');
            city = teamName.substring(0, lastIndex);
            mascot = teamName.substring(lastIndex, teamName.length);
        }
        return (
            <div className={`${className}--container-name`}>
                {city} <span className="mascot">{mascot}</span>
            </div>
        );
    }

    render() {
        const { className, teamName, logoUrl, slug } = this.props;

        return (
            <div className={className} data-team={teamName}>
                <div className={`${className}--container`}>
                    <Logo className={`${className}--container-logo`} src={logoUrl} />
                    {this.renderDisplayName(className, teamName)}
                </div>
            </div>
        );
    }
}

TeamBar.propTypes = {
    className: PropTypes.string,
    teamName: PropTypes.string,
    logoUrl: PropTypes.string,
    slug: PropTypes.string
};

TeamBar.defaultProps = {
    className: 'team__bar',
    teamName: '',
    logoUrl: '',
    slug: ''
};

export default TeamBar;
