import React from 'react';
import PropTypes from 'prop-types';
import { Image, Link } from '../../index';
import 'lazysizes';
import './styles.less';

const StoryPreview = (props) => {
    const { className, imgCuts, alt, slug, teamContext } = props;

    return (
        <div className={`${className}`}>
            <Link href={`${teamContext.path || ''}/news/${slug}`} title={alt}>
                <Image lazyload={true} srcSet={imgCuts} alt={alt} aspectRatio={'16:9'} />
            </Link>
        </div>
    );
};

StoryPreview.propTypes = {
    className: PropTypes.string,
    imgCuts: PropTypes.string,
    alt: PropTypes.string,
    slug: PropTypes.string,
    teamContext: PropTypes.object
};

StoryPreview.defaultProps = {
    className: 'story__preview',
    imgSrc: '',
    alt: '',
    slug: '',
    teamContext: {}
};

export default StoryPreview;
