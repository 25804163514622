/* eslint-disable no-undef */
const _get = require('lodash.get');
const _isString = require('lodash.isstring');

// create digitalData layer
const setDigitalData = () => {
    if (window.sectionConfig && window.pageConfig) {
        const sectionConfig = JSON.parse(unescape(window.sectionConfig));
        window.digitalData = window.digitalData || {};
        window.digitalData.currentPage = {
            pageName: getPageName(sectionConfig)
        };
        setSectionTracking(sectionConfig.id);
    }
};

// This will return the specific page we are on
// e.g. /info/about will return /about
const getPageName = (sectionConfig) => {
    if (sectionConfig.id === 'home') {
        return 'yes network : home';
    }

    const route = sectionConfig.path || window.location.pathname;
    const routeParts = route.split('/');
    let pageName = 'index';
    if (routeParts.length > 1) {
        pageName = routeParts[routeParts.length - 1];
        if (sectionConfig.id === 'video') {
            if (routeParts.includes('topic')) {
                pageName = `topic : ${pageName}`;
            } else {
                pageName = `video player : ${pageName}`;
            }
        } else if (sectionConfig.id === 'news') {
            pageName = routeParts[1] === 'news' ? 'index' : routeParts[1];
        }
    }

    return `yes network : ${sectionConfig.id} : ${pageName}`;
};

const setSectionTracking = (section) => {
    const initData = JSON.parse(unescape(window.INIT_DATA));
    if (initData) {
        if (section === 'news') {
            setArticleMetadata(initData);
        } else if (section === 'video') {
            const videoData = JSON.parse(initData.initData);
            setVideoMetadata(videoData);
        }
    }
};

const setArticleMetadata = (initData) => {
    const headline = _get(initData, 'article.headline', '');
    window.digitalData.currentPage.pageName += ` : ${headline.toLowerCase()}`;
    window.digitalData.currentPage.article = {
        contentId: _get(initData, 'slug'),
        contentHeadline: headline,
        author: _get(initData, 'article.fields.byline')
    };
};

const setVideoMetadata = (data) => {
    // This method will fire either on initial page load or from the trackPageView fn
    // Data from trackPageView comes from the player as a trackingData obj with clipData and topicTitle props
    const clipData = _get(data, 'clip.data', data.clipData);
    const topicTitle = _get(data, 'topic.data.title', data.topicTitle);
    if (clipData && topicTitle) {
        window.digitalData.video = {
            title: clipData.title,
            slug: clipData.slug,
            contentId: clipData.content_id,
            category: topicTitle
        };
    }
};

// Iterate over an array of event strings and send action data to push method
const videoEventHandler = (events) => {
    const EVENT_MAP = {
        event27: 'video start',
        event28: 'video complete',
        event67: 'video 25% complete',
        event68: 'video 50% complete',
        event69: 'video 75% complete'
    };

    if (Array.isArray(events)) {
        events.forEach((e) => {
            EVENT_MAP[e] &&
                dataLayerEvent({
                    eventName: EVENT_MAP[e],
                    video: _get(window, 'digitalData.video', {})
                });
        });
    }
};

// used on global window for video tracking calls on subsequent video clicks
const trackAsync = (value) => {
    if (!window.s) {
        window.s = window.s_gi(window.s_name);
    }
    // Extract events from params and pass to eventHandler
    if (value && _isString(value.events)) {
        const events = value.events.split(',');
        videoEventHandler(events);
    }

    if (window.sectionConfig) {
        const sectionConfig = JSON.parse(unescape(window.sectionConfig));
        // eVar72 Contains the Video URL when a new dropdown is clicked
        if (value && value.eVar72) {
            const videoURL = new URL(value.eVar72);
            sectionConfig.path = videoURL.pathname;
        }
        // fire new pageview only if pagename has just changed as a result of event
        const pageName = getPageName(sectionConfig);
        if (s.pageName !== pageName) {
            s.pageName = pageName;
            s.t();
        }
    }
};

/**
 *  Modernized event handler for digitalData push events, this function calls
 *  digitalData.push with the appropriate data received from the callee
 * @param {object} eventData Contains eventName (name of the event as per spec) and other relevant data
 */
const dataLayerEvent = (eventData = { eventName: '' }) => {
    if (window.sectionConfig) {
        const sectionConfig = JSON.parse(unescape(window.sectionConfig));
        const section = sectionConfig.id === 'news' ? 'article' : sectionConfig.id;
        const pageName = getPageName(sectionConfig);
        const { eventName, pageDepth, targetUrl, video } = eventData;

        window.digitalData.push({
            event: `${section} event`,
            action: {
                name: `${pageName} : ${eventName}`,
                ...(pageDepth && { pageDepth }), // Add these properties if they exist
                ...(video && { video }),
                ...(targetUrl && { targetUrl: `https://www.yesnetwork.com${targetUrl}` })
            }
        });
    }
};

const trackPageView = (data) => {
    if (data) {
        setVideoMetadata(data);
    }
};

export { dataLayerEvent, setDigitalData, trackAsync, trackPageView };
