export default [
    {
        name: 'svg',
        attrs: {
            xmlns: 'http://www.w3.org/2000/svg',
            viewBox: '0 0 512 512'
        },
        childs: [
            {
                name: 'title',
                attrs: {},
                childs: [
                    {
                        text: 'Close icon'
                    }
                ]
            },
            {
                name: 'path',
                attrs: {
                    class: 'st0',
                    d:
                        'M10.3 488.6c13.8 13.1 36.2 13.1 49.9 0l195.6-185.5 195.6 185.5c13.8 13.1 36.2 13.1 49.9 0 13.8-13.1 13.8-34.3 0-47.4L305.8 255.7 501.3 70.2c13.8-13.1 13.8-34.3 0-47.4-13.8-13.1-36.2-13.1-49.9 0L255.8 208.3 60.3 22.8c-13.8-13.1-36.2-13.1-49.9 0-13.8 13.1-13.8 34.3 0 47.4L206 255.7 10.3 441.2c-13.7 13.1-13.7 34.4 0 47.4z'
                }
            }
        ],
        id: 'close'
    }
];
