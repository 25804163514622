import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { messages, constants } from './messages';
import './styles.less';

class Error extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { className, error, debug } = this.props;
        const { status, stack, message } = error;
        const { ERROR_TITLE, BACK_BUTTON_TEXT } = constants;
        const displayMessage = messages[status];

        return (
            <div className={className}>
                <div className={`${className}--container`}>
                    <h1 className={`${className}--container-title`}>{ERROR_TITLE}</h1>
                    <h3 className={`${className}--container-message`}>{displayMessage}</h3>
                    <h4 className={`${className}--container-support-message`}>
                        {'Please try the action again and if the problem continues, contact '}
                        <a href="/info/help-center">Customer Support.</a>
                    </h4>
                    <h5 className={`${className}--container-error-detail`}>{message}</h5>
                    <a href="/" className={'p-button p-button__button'}>
                        {BACK_BUTTON_TEXT}
                    </a>
                </div>
                {debug && <pre>{stack}</pre>}
            </div>
        );
    }
}

Error.propTypes = {
    className: PropTypes.string,
    error: PropTypes.object,
    debug: PropTypes.bool
};

Error.defaultProps = {
    className: 'error__page',
    error: {
        status: 500,
        message: "We aren't able to display that page right now. Please try again in a little while."
    },
    debug: false
};

export default Error;
