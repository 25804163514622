const setTakeover = (skin) => {
    var $HTML = $('html');
    var $SKIN = $('#root');
    var $SECTION_CONTAINER = $('.section__container');
    var bgColor = skin.bgColor || '#000000';
    var bgImage = skin.bgImage ? 'url(' + skin.bgImage + ')' : 'none';
    var bgPosition = skin.bgPosition || 'center top';
    var bgRepeat = skin.bgRepeat || 'no-repeat';
    var bgAttachment = skin.bgAttachment || 'scroll';

    $HTML.addClass('sponsored-takeover');

    $SKIN.css({
        'background-color': bgColor,
        'background-image': bgImage,
        'background-position': bgPosition,
        'background-repeat': bgRepeat,
        'background-attachment': bgAttachment
    });

    if (skin.clickHref) {
        var clickHref = skin.clickHref;
        var clickTarget = skin.clickTarget || '_blank';
        var clickHeight = skin.clickHeight || '900px';
        var clickTop = skin.clickTop || $SECTION_CONTAINER.position().top || '0';

        // allow one instance of the sponsored takeover anchor
        if (!$HTML.find('#sponsored-takeover-link').length) {
            $('<a></a>')
                .attr({
                    id: 'sponsored-takeover-link',
                    target: clickTarget,
                    href: clickHref
                })
                .css({
                    display: 'block',
                    position: 'absolute',
                    'z-index': '0',
                    width: '100%',
                    height: clickHeight,
                    top: clickTop
                })
                .prependTo($SKIN);
        }
    }
};

export { setTakeover };
