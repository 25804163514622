import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const SocialIcon = ({ className = '', socialName = '', url = '', newWindow = false, onClickHandler, title = '' }) => {
    if (socialName) {
        return (
            <a
                className={`${className} ${className}--link`}
                href={url}
                target={newWindow ? '_blank' : '_parent'}
                rel={newWindow ? 'noopener noreferrer' : null}
                onClick={onClickHandler}
            >
                <i className={`fa fa-${socialName.toLowerCase()}`} title={title} />
            </a>
        );
    }

    return null;
};

export default SocialIcon;

SocialIcon.propTypes = {
    className: PropTypes.string,
    socialName: PropTypes.string,
    url: PropTypes.string,
    newWindow: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onClickHandler: PropTypes.func,
    title: PropTypes.string
};
