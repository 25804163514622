import React from 'react';
import PropTypes from 'prop-types';

const DropdownItem = (props) => {
    const { className, target, text, url } = props;

    return (
        <div className={`${className}__item`}>
            <a className={`${className}__item--link`} href={url} target={target}>
                {text}
            </a>
        </div>
    );
};

DropdownItem.propTypes = {
    className: PropTypes.string,
    target: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string
};

DropdownItem.defaultProps = {
    className: 'dropdown',
    text: '',
    url: ''
};

export default DropdownItem;
