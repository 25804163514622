import React from 'react';
import PropTypes from 'prop-types';
import { Image, Link } from '../../index';
import './styles.less';

const VideoPreview = (props) => {
    const { className, imgCuts, alt, hbContentId, slug, topicId } = props;

    return (
        <div className={`${className}`}>
            <Link href={`/video/${hbContentId || slug}${topicId}`} title={alt}>
                <Image lazyload={true} srcSet={imgCuts} alt={alt} aspectRatio={'16:9'} />
                <i className={'fa fa-play play-button'} />
            </Link>
        </div>
    );
};

VideoPreview.propTypes = {
    className: PropTypes.string,
    imgCuts: PropTypes.string,
    alt: PropTypes.string,
    hbContentId: PropTypes.string,
    slug: PropTypes.string,
    topicId: PropTypes.string
};

VideoPreview.defaultProps = {
    className: 'video__preview',
    imgCuts: '',
    alt: '',
    hbContentId: '',
    slug: '',
    topicId: ''
};

export default VideoPreview;
