// templates/App.jsx

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import path from 'path';
import urljoin from 'url-join';

// Components used throughout site
import { Footer, Header } from './index';

class App extends Component {
    constructor(props) {
        super(props);
        this.getEntryScripts = this.getEntryScripts.bind(this);
        this.getEntryStyles = this.getEntryStyles.bind(this);
        this.createURL = this.createURL.bind(this);
    }

    render() {
        const {
            baseURL,
            entrypoints,
            footer,
            header,
            headerProps,
            manifest,
            navigation,
            pageId,
            sectionId,
            server
        } = this.props;

        /* If a section doesn't need a Header, pass header=false in App props */
        function renderHeader() {
            if (header) {
                return <Header {...headerProps} hideNavigation={!navigation} />;
            } else {
                return null;
            }
        }

        /* If a section doesn't need a Footer, pass footer=false in App props */
        function renderFooter() {
            if (footer) {
                return <Footer />;
            } else {
                return null;
            }
        }

        return (
            <html lang="en">
                <head>
                    <title>YES Network</title>
                    <meta name="viewport" content="width=device-width,initial-scale=1" />
                    <link rel="stylesheet" href={this.createURL(baseURL, manifest['core.css'])} type="text/css" />
                    {this.getEntryStyles(sectionId, pageId, entrypoints, manifest, baseURL)}
                    <script src={server.authentication.okta_script} />
                    <script
                        dangerouslySetInnerHTML={{
                            __html: `window.OKTA_CONFIG = '${escape(JSON.stringify(server.okta_config))}';
                                     window.HEADER_INIT_DATA = '${escape(JSON.stringify(headerProps))}';`
                        }}
                    />
                </head>
                <body>
                    {renderHeader()}
                    <div id="root" className="layout">
                        {this.props.children}
                    </div>
                    {renderFooter()}
                    {this.getEntryScripts(sectionId, pageId, entrypoints, manifest, baseURL)}
                    <script type="text/javascript" src={this.createURL(baseURL, manifest['runtime.js'])}></script>
                    <script type="text/javascript" src={this.createURL(baseURL, manifest['vendors.js'])}></script>
                    <script type="text/javascript" src={this.createURL(baseURL, manifest['core.js'])}></script>
                    <script type="text/javascript" src={this.createURL(baseURL, manifest['hot.js'])}></script>
                </body>
            </html>
        );
    }

    getEntryScripts(sectionId, pageId, entrypoints, manifest, baseURL) {
        if (entrypoints.scripts) {
            const prop = path.join(sectionId, pageId, 'scripts.js');
            const src = manifest[prop];
            return <script type="text/javascript" src={this.createURL(baseURL, src)}></script>;
        }
    }

    getEntryStyles(sectionId, pageId, entrypoints, manifest, baseURL) {
        if (entrypoints.styles) {
            const prop = path.join(sectionId, pageId, 'styles.css');
            const src = manifest[prop];
            return <link rel="stylesheet" href={this.createURL(baseURL, src)} type="text/css" />;
        }
    }

    createURL(base, path) {
        if (base && path) {
            return urljoin(base, path);
        }
        return '';
    }
}

App.propTypes = {
    manifest: PropTypes.object,
    sectionId: PropTypes.string,
    pageId: PropTypes.string,
    entrypoints: PropTypes.object,
    children: PropTypes.node.isRequired,
    baseURL: PropTypes.string.isRequired,
    headerProps: PropTypes.object,
    server: PropTypes.object.isRequired,
    header: PropTypes.bool,
    footer: PropTypes.bool,
    navigation: PropTypes.bool
};

App.defaultProps = {
    header: true,
    footer: true,
    navigation: true
};

export default App;
