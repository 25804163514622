import React from 'react';
import PropTypes from 'prop-types';
import './styles.less';

const ListModule = (props) => {
    const { className, title, items } = props;
    return (
        <div className={`${className}`}>
            <div className={`${className}-title`}>{title}</div>
            <div className={`${className}-divider`} />
            <div className={`${className}-items`}>
                {items.map((item, key) => {
                    return (
                        <div className={`${className}-items-item`} key={key}>
                            <a href={item.href}>{item.text}</a>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

ListModule.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.array
};

ListModule.defaultProps = {
    className: 'list__module',
    title: '',
    items: []
};

export default ListModule;
