import React, { Component } from 'react';
import PropTypes from 'prop-types';
import scrollMonitor from 'scrollMonitor';
import classNames from 'classnames';
import './styles.less';

class ScrollTopButton extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            show: false,
            absolute: false
        };

        // Member variables needed for computations
        this.INIT_OFFSET = 0;
        this.WATCHERS = {
            elemWatcher: props.elem,
            absoluteWatcher: props.absoluteElem
        };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(e) {
        document.body.scrollTop = 0; // safari
        document.documentElement.scrollTop = 0;
        // Track this action
        window.dataLayerEvent &&
            window.dataLayerEvent({
                eventName: 'back to top click',
                pageDepth: this.props.pageDepth
            });
    }

    componentDidMount() {
        let { elemWatcher, absoluteWatcher } = this.WATCHERS;

        if (elemWatcher) {
            elemWatcher = scrollMonitor.create(elemWatcher); // must initialize here or document error will be thrown server side
            this.WATCHERS.elemWatcher = elemWatcher; // update member var

            elemWatcher.enterViewport(() => {
                this.INIT_OFFSET = document.body.scrollTop || document.documentElement.scrollTop;
                this.setState({
                    show: true
                });
            });
            elemWatcher.exitViewport(() => {
                this.setState({
                    show:
                        document.body.scrollTop > this.INIT_OFFSET ||
                        document.documentElement.scrollTop > this.INIT_OFFSET // show button even out of view if we havent scrolled before our initial position
                });
            });
        }

        if (absoluteWatcher) {
            absoluteWatcher = scrollMonitor.create(absoluteWatcher);
            this.WATCHERS.absoluteWatcher = absoluteWatcher; // update member var

            absoluteWatcher.enterViewport(() => {
                this.setState({
                    absolute: true
                });
            });
            absoluteWatcher.exitViewport(() => {
                this.setState({
                    absolute: false
                });
            });
        }
    }

    componentDidUpdate(prevProps) {
        const { absoluteWatcher } = this.WATCHERS;

        if (absoluteWatcher && prevProps.pageDepth < this.props.pageDepth) {
            // if page depth increases, then absolute positioned element may now be out of view
            absoluteWatcher.recalculateLocation();
            absoluteWatcher.update();
            this.setState({
                absolute: absoluteWatcher.isInViewport
            });
        }
    }

    componentWillUnmount() {
        const { elemWatcher, absoluteWatcher } = this.WATCHERS; // clean up listeners and watchers
        elemWatcher && elemWatcher.destroy();
        absoluteWatcher && absoluteWatcher.destroy();
    }

    render() {
        const { className } = this.props;
        const { show, absolute } = this.state;

        return (
            show && (
                <button className={classNames('scroll-top-btn', [className], { absolute })} onClick={this.handleClick}>
                    <i className="fa fa-angle-up fa-3x" />
                </button>
            )
        );
    }
}

ScrollTopButton.propTypes = {
    className: PropTypes.string,
    elem: PropTypes.any, // Elem to watch. When in view, show button. [DOM/jquery elem, selector string, nodeList/array]
    absoluteElem: PropTypes.any, // Elem to watch. When in view, position = absolute. [DOM/jquery elem, selector string, nodeList/array],
    pageDepth: PropTypes.number // Keep track of page depth as it can modify positions of elements without scrolling - i.e button clicks to extend length of container
};

ScrollTopButton.defaultProps = {
    className: '',
    elem: '',
    absoluteElem: '',
    pageDepth: 0
};

export default ScrollTopButton;
