/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Image, Link, RelatedItems } from '../../index';
import './styles.less';

class Hero extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isHovering: false
        };

        this.handleHeroClick = this.handleHeroClick.bind(this);
        this.handleMouseEnter = this.handleMouseEnter.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
    }

    handleMouseEnter() {
        this.setState({ isHovering: true });
    }

    handleMouseLeave() {
        this.setState({ isHovering: false });
    }

    handleHeroClick() {
        const { newWindow, url } = this.props;
        window.open(url, newWindow ? '_blank' : '_self');
    }

    render() {
        const {
            alt,
            blurb,
            className,
            headline,
            imageURL,
            imgCuts,
            newWindow,
            relatedItems,
            url,
            urlText
        } = this.props;
        const { isHovering } = this.state;

        return (
            <React.Fragment>
                <div
                    className={className}
                    style={{
                        backgroundImage: `url(${imageURL})`
                    }}
                >
                    <div
                        className={`${className}--gradient`}
                        onClick={this.handleHeroClick}
                        onMouseEnter={this.handleMouseEnter}
                        onMouseLeave={this.handleMouseLeave}
                    />
                    <div className={`${className}--content`}>
                        <span
                            className={classNames(`${className}--content-primary-link`, { hovering: isHovering })}
                            onClick={this.handleHeroClick}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                            tabIndex="0"
                            role="link"
                        >
                            <span className={`${className}--content-headline`}>{headline}</span>
                        </span>
                        <div
                            className={`${className}--content-blurb`}
                            onClick={this.handleHeroClick}
                            onMouseEnter={this.handleMouseEnter}
                            onMouseLeave={this.handleMouseLeave}
                        >
                            {blurb}
                            {urlText && (
                                <span
                                    className={classNames(`${className}--content-cta`, {
                                        hovering: isHovering
                                    })}
                                >
                                    <span className={`${className}--content-cta-link`}>{urlText}&nbsp;&gt;&gt;</span>
                                </span>
                            )}
                        </div>
                        <RelatedItems className={`${className}--content-related-links`} relatedItems={relatedItems} />
                    </div>
                </div>
                <div className={`${className}-mobile`}>
                    <Link className={`${className}-mobile-link`} href={url} target={newWindow ? '_blank' : undefined}>
                        <Image
                            className={`${className}-mobile-image`}
                            lazyload={false}
                            srcSet={imgCuts}
                            alt={alt}
                            aspectRatio="5:2"
                        />
                    </Link>
                    <Link className={`${className}-mobile-link`} href={url} target={newWindow ? '_blank' : undefined}>
                        <span className={`${className}-mobile-headline`}>{headline}</span>
                    </Link>
                    <p className={`${className}-mobile-blurb`}>
                        {blurb}
                        {urlText && (
                            <Link
                                className={`${className}-mobile-link`}
                                href={url}
                                target={newWindow ? '_blank' : undefined}
                            >
                                <span className={`${className}-mobile-link-cta`}>{urlText}&nbsp;&gt;&gt;</span>
                            </Link>
                        )}
                    </p>
                    <div className={'divider'} />
                </div>
            </React.Fragment>
        );
    }
}

Hero.propTypes = {
    alt: PropTypes.string,
    blurb: PropTypes.string,
    className: PropTypes.string,
    headline: PropTypes.string,
    imageURL: PropTypes.string,
    imgCuts: PropTypes.string,
    newWindow: PropTypes.bool,
    relatedItems: PropTypes.array,
    url: PropTypes.string,
    urlText: PropTypes.string
};

Hero.defaultProps = {
    alt: '',
    blurb: '',
    className: 'hero__container',
    headline: '',
    imageURL: '',
    imgCuts: '',
    newWindow: false,
    relatedItems: [],
    url: 'https://www.yesnetwork.com/',
    urlText: ''
};

export default Hero;
