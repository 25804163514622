/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { Caret, Dropdown, isValidUrl } from '../../index';

const NavItem = ({ className = '', baseURL, hasDropdown, navItem, onClick, targetTop = false }) => {
    if (navItem) {
        const { link, target } = navItem && navItem.properties;
        const navLink = isValidUrl(link) ? link : `${baseURL}${link}`;
        const linkTarget = targetTop ? '_top' : target;
        return (
            <li key={`${navItem.text}--nav-item`} dropdown={`${hasDropdown}`} className={className}>
                <a
                    className="nav__items--label"
                    href={navLink}
                    onClick={() => onClick(navItem.text)}
                    target={linkTarget}
                >
                    {navItem.text.toUpperCase()}
                    {hasDropdown && <Caret />}
                </a>
                {hasDropdown && <Dropdown baseURL={baseURL} dropdownItems={navItem.menuItems} />}
            </li>
        );
    }

    return null;
};

export default NavItem;

NavItem.propTypes = {
    baseURL: PropTypes.string,
    className: PropTypes.string.isRequired,
    hasDropdown: PropTypes.bool,
    navItem: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    targetTop: PropTypes.bool
};

NavItem.defaultProps = {
    baseURL: 'https://www.yesnetwork.com'
};
