import React from 'react';
import PropTypes from 'prop-types';

const GridRow = (props) => {
    const { className, children, title, wrapper: Wrapper } = props;
    return (
        <div className={className}>
            {title ? <div className={`${className}--header`}>{title}</div> : null}
            <div className={`${className}--grid`}>
                {children.map((component, key) => {
                    return (
                        <Wrapper
                            className={`${className}-${Wrapper.defaultProps.className}`}
                            {...component}
                            key={key}
                        />
                    );
                })}
            </div>
        </div>
    );
};

GridRow.propTypes = {
    className: PropTypes.string,
    wrapper: PropTypes.Component,
    children: PropTypes.array.isRequired,
    title: PropTypes.string
};

GridRow.defaultProps = {
    className: 'grid__row',
    children: []
};

export default GridRow;
