import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './styles.less';

const Select = (props) => {
    const { className, name, onChange, options, value } = props;

    return (
        <div className="p-select">
            <select
                className={classNames(['p-select--select', [className]])}
                name={name}
                value={value}
                onChange={onChange}
            >
                {options.map((opt) => {
                    return (
                        <option className="p-select--option" key={`${name}-option-${opt.value}`} value={opt.value}>
                            {opt.label}
                        </option>
                    );
                })}
            </select>
        </div>
    );
};

Select.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.object),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Select.defaultProps = {
    options: []
};

export default Select;
