import React from 'react';
import PropTypes from 'prop-types';
import { Image, Link } from '../../index';
import 'lazysizes';
import './styles.less';

const Tile = ({ className = 'tile', imgCuts, alt, url, isPopup }) => {
    const target = isPopup ? '_blank' : '';

    return (
        <div className={`${className}`}>
            <Link href={url} title={alt} target={target}>
                <Image lazyload={true} srcSet={imgCuts} alt={alt} aspectRatio={'16:9'} />
            </Link>
        </div>
    );
};

Tile.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    imgCuts: PropTypes.string,
    isPopup: PropTypes.bool,
    url: PropTypes.string
};

Tile.defaultProps = {};

export default Tile;
