import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MenuItem } from '../../index';
import './styles.less';

class Menu extends Component {
    constructor(props) {
        super(props);
        this.props = props;
        this.state = {
            activeItem: undefined
        };
        this.handleItemClick = this.handleItemClick.bind(this);
    }

    handleItemClick(menuItem) {
        this.setState((prevState) => ({
            activeItem: prevState.activeItem === menuItem ? undefined : menuItem
        }));
    }

    renderMenuItems(className, tabs) {
        const { baseURL } = this.props;
        return tabs.map((tab, key) => {
            return (
                <MenuItem
                    baseURL={baseURL}
                    navItem={tab}
                    className={`${className}__items--item`}
                    hasDropdown={tab.menuItems.length > 0}
                    key={key}
                    onClick={this.handleItemClick}
                    isActive={this.state.activeItem === tab}
                />
            );
        });
    }

    render() {
        const { className, tabs, socialIcons, renderSocialIcons, style } = this.props;

        return (
            <nav className={className}>
                <div className={`${className}--container`} style={style}>
                    <ul className={`${className}__items`}>{this.renderMenuItems(className, tabs)}</ul>
                    <div className="nav__social--icons social--icons">{renderSocialIcons(socialIcons)}</div>
                </div>
            </nav>
        );
    }
}

Menu.propTypes = {
    className: PropTypes.string,
    tabs: PropTypes.array,
    socialIcons: PropTypes.array,
    renderSocialIcons: PropTypes.func,
    style: PropTypes.object,
    baseURL: PropTypes.string
};

Menu.defaultProps = {
    className: 'nav__menu',
    tabs: [
        { text: 'yankees', properties: { link: '' } },
        { text: 'nets', properties: { link: '' } },
        { text: 'nycfc', properties: { link: '' } },
        { text: 'liberty', properties: { link: '' } },
        { text: 'shows', properties: { link: '' } },
        { text: 'personalities', properties: { link: '' } },
        { text: 'my yes', properties: { link: '' } }
    ],
    socialIcons: [{ name: 'facebook' }, { name: 'twitter' }, { name: 'instagram' }, { name: 'envelope-o' }],
    renderSocialIcons: () => {},
    style: {
        height: '100%'
    }
};

export default Menu;
