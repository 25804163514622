const messages = {
    400: 'The service had a connection problem. If the problem persists, try a different web browser or reboot your computer.',
    401: 'That page is accessible only to subscribers. Sign in or create an account.',
    403: 'There is no page for that link. Did you type the link correctly?',
    404: 'We did not find the page you were looking for. Did you type the link correctly?',
    500: "We aren't able to display that page right now. Please try again in a little while."
};

const constants = {
    ERROR_TITLE: 'OOPS!',
    BACK_BUTTON_TEXT: 'Back to Home'
};

export { messages, constants };
