import React from 'react';
import PropTypes from 'prop-types';

const Caret = ({ direction = 'down' }) => {
    return <i className={`fa fa-caret-${direction}`} aria-hidden="true" />;
};

export default Caret;

Caret.propTypes = {
    direction: PropTypes.string
};
