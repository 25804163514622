import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ className = '', src = '', alt = '', url = '', newWindow = false }) => {
    if (src) {
        return (
            <div className={className}>
                {url ? (
                    <a
                        className={`${className}--link`}
                        href={url}
                        target={newWindow ? '_blank' : '_parent'}
                        rel={newWindow ? 'noopener noreferrer' : null}
                    >
                        <img src={src} alt={alt} />
                    </a>
                ) : (
                    <img src={src} alt={alt} />
                )}
            </div>
        );
    }

    return null;
};

export default Logo;

Logo.propTypes = {
    src: PropTypes.string,
    alt: PropTypes.string,
    className: PropTypes.string,
    url: PropTypes.string,
    newWindow: PropTypes.bool
};
