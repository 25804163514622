/* eslint no-unused-vars:0 */
// import global scripts here
//require( 'bootstrap' );
import 'jquery';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
// import setOktaConfig from './okta';
import { dataLayerEvent, setDigitalData, trackAsync, trackPageView } from './tracking';
import { setTakeover } from './sponsored-ads';
import lazySizes from 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg';

import React from 'react';
import { hydrate } from 'react-dom';
import { Ad, Header } from '@yes/components';

// Disable until we have login/account.
// setOktaConfig();
setDigitalData();
window.trackAsync = trackAsync;
window.trackPageView = trackPageView;
window.setTakeover = setTakeover;
window.dataLayerEvent = dataLayerEvent;

const hideNavigation = window.HIDE_NAV === 'true';
const showHeader = window.SHOW_HEADER === 'true';
const env = window.ENV;

// Need to hydrate header (on top of SSR'ing it) across all sections to attach client-side functionality and allow for interactivity
// If request is 500 we don't want to hydrate the header so that it appears
if (showHeader) {
    const headerInitData = JSON.parse(unescape(window.HEADER_INIT_DATA));
    hydrate(<Header hideNavigation={hideNavigation} env={env} {...headerInitData} />, document.getElementById('header'));
}

// video section needs to hydrate skin ad due to playback-app
if (document.getElementById('video-skin-ad')) {
    const INIT_DATA = JSON.parse(unescape(window.INIT_DATA));
    const skinAdProps = JSON.parse(unescape(INIT_DATA.initData)).skinAdProps;
    hydrate(<Ad {...skinAdProps}/>, document.getElementById('video-skin-ad'));
}
