import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './styles.less';
import { MixedFeedItem } from '../../index';

class MixedFeed extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { className, mediaItems, title, rowSize, containsAd, sectionName } = this.props;

        // Temporary until we implement ads, lets think how to incorp into Feed
        if (containsAd) {
            mediaItems.push({ type: 'ad', sectionName });
        }

        return (
            <div className={className}>
                {title && <div className={`${className}--header`}>{title}</div>}
                <div className={`${className}--grid`}>
                    {mediaItems.map((mediaItem, key) => {
                        return <MixedFeedItem className={`${className}--media-item`} mediaItem={mediaItem} key={key} />;
                    })}
                </div>
            </div>
        );
    }
}

MixedFeed.propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    mediaItems: PropTypes.array,
    rowSize: PropTypes.number,
    containsAd: PropTypes.bool,
    sectionName: PropTypes.oneOf(['home', 'entertainment', 'video', 'login', 'account'])
};

MixedFeed.defaultProps = {
    className: 'mixed__feed',
    title: '',
    mediaItems: [],
    rowSize: 3,
    containsAd: false
};

export default MixedFeed;
