const _get = require('lodash.get');
const _isString = require('lodash.isstring');

const VALID_SOCIALS = {
    facebook: true,
    twitter: true,
    instagram: true,
    subscribe: true
};
/**
 * Constructs the image path to forge and transforms parameters to templates in cloudinary
 *
 * @param {String} path the cloudinary URL path to the image with replacement variables
 * @param {String} type type of image requested
 * @param {Integer} width width of the image requested, should be one of the template widths
 *                  in cloudinary: 2208, 1536, 1024, 640, 372
 * @param {String} aspectRatio aspect ratio of the image requested,
 *                  should be one of the template aspect ratios in
 *                  cloudinary: 16:9, 4:1, 5:2, 4:5, 1:1
 * @returns {String} a URL string to the image path in forge
 */
function getImagePath(path, type = 'jpg', width = 1024, aspectRatio = '16:9', gravity = 'auto') {
    // const templateAspectRatio = `t_${aspectRatio.replace(':', 'x')}`;
    const templateAspectRatio = `ar_${aspectRatio}`;
    const templateWidth = `w_${width}`;
    const templateGravity = `g_${gravity}`;
    const templateCrop = 'c_fill';
    let templates = [templateAspectRatio, templateGravity, templateCrop];
    if (type !== 'gif') {
        templates.push(templateWidth);
        // templates.push(templateGravity);
    }
    let mapItems = {
        'http:': 'https:',
        '{CloudinaryStorageCloudName}': 'mlb-images', // CloudinaryStorageCloudName should be 'dqv3zmw8a' for dev azure
        '{formatInstructions}': `${templates.join(',')}`
    };

    const newPath = path.replace(
        /http:|{CloudinaryStorageCloudName}|{formatInstructions}/gi,
        (matched) => mapItems[matched]
    );

    return newPath;
}

/**
 * Create an object that contains paths to social images.
 *
 * @param {Object} [thumbnail={}] A `thumbnail` object from Forge.
 * @returns {String} This returns string path to social images created by Cloudinary
 */
function getShareImage(thumbnail = {}) {
    if (thumbnail.templateUrl) {
        const shareImgSrc = getImagePath(thumbnail.templateUrl, 'jpg', 1536, '2:1');

        return `${shareImgSrc}.jpg`;
    }

    return undefined;
}

/**
 * Transforms an image object from forge to homebase-like data that the image pattern can consume
 * @param {options} options - object of params
 * @param options.image [image={}] {Object} image object from forge that should have properties of `format`,
 *                       `templateUrl`, and `title`
 * @param options.primary [primary=false] {Boolean} if this should be the primary image of an article
 * @param options.width [width=1014] {Integer} The width of the image to transform
 * @param options.aspectRatio [aspectRatio='16:9'] {String} The aspect ratio needed
 * @param options.className [className='article-content'] {String} The class to attach to the image pattern
 * @returns {Object} an object that the image pattern can consume and display
 */
function transformImage(options) {
    if (!options) {
        return {};
    }

    const { image = {}, primary = false, width = 1024, aspectRatio = '16:9', className = 'article-content' } = options;

    const { templateUrl = '', format = '', title = '' } = image;
    const imagePath = templateUrl ? getImagePath(templateUrl, format, width, aspectRatio) : '';
    const type = format === 'gif' ? 'gif' : 'photo';

    if (format !== 'gif') {
        const cuts = generateCuts(templateUrl, format, aspectRatio);
        return {
            type,
            src: imagePath,
            image: {
                aspectRatio,
                alt: title,
                cuts,
                class: className
            }
        };
    } else {
        const mediaType = primary ? 'primary-gif' : 'article-content-gif';
        return {
            type,
            auto: {
                id: mediaType,
                alt: title,
                src: imagePath && `${imagePath}.gif`,
                gif: imagePath && `${imagePath}.gif`,
                mp4: imagePath && `${imagePath}.mp4`,
                autoPlay: true
            },
            noAuto: {
                id: mediaType,
                alt: title,
                src: imagePath && `${imagePath}.gif`,
                gif: imagePath && `${imagePath}.gif`,
                mp4: imagePath && `${imagePath}.mp4`,
                autoPlay: false
            }
        };
    }
}

/**
 * Creates an array of cut objects that the image pattern can consume
 *
 * @param {String} templateUrl defaults to 'https://img.mlbstatic.com/opprops-images/image/private/q_100/v1587130047/opprops/meq0e7zizmx8sn8bzwxg',
 * @param {String} format defaults to 'jgp', as defined by forge
 * @param {String} aspectRatio defaults to '16:9'
 * @returns {Array} Array of `cut` objects
 */
function generateCuts(
    templateUrl = 'https://img.mlbstatic.com/opprops-images/image/private/q_100/v1587130047/opprops/meq0e7zizmx8sn8bzwxg',
    format = 'jpg',
    aspectRatio = '16:9',
    gravity = 'auto'
) {
    // theres no need to generate super large images currently, let's revisit this as site is built
    const cloudinaryTemplates = {
        widths: ['t_w1024', 't_w640', 't_w372']
    };

    return cloudinaryTemplates.widths.reduce((arr, templateWidth) => {
        const width = parseInt(templateWidth.replace('t_w', ''), 10);
        const cut = {
            aspectRatio,
            width,
            src: `${getImagePath(templateUrl, format, width, aspectRatio, gravity)}.jpg`
        };
        arr.push(cut);
        return arr;
    }, []);
}

/**
 * Creates an array of cutsthat can be passed directly to a src-set
 * @param {object} src Array of 'cut' objects
 * @returns {string} String representing src-set attribute
 */
function parseCutsObj(src) {
    return src.length
        ? src
              .map((elem) => {
                  return `${elem.src} ${elem.width}w`;
              })
              .reduce((acc, curr) => {
                  return `${acc}, ${curr}`;
              })
        : undefined;
}

// Short content text comes back as one string with span tag. Can we ask them to split this up better
function getShortContent(blurb) {
    if (!_isString(blurb)) return;
    var blurbParts = blurb.split('<'); // split by span tag
    var firstPart = blurb.split('<')[0]; // first part of blurb e.g. Today on YES
    var spanContents = blurbParts[1].split('>')[1]; // second part in span, e.g. Watch Michael Kay at 3pm

    return [firstPart, spanContents];
}

/**
 * Generic function to transforms raw data from forge into normalized link data to be consumed by ListModule
 * @param {object} data Raw data payload from forge
 * @param {string} title Title of ListModule
 *
 * @return {array} Array containing news items for specific team
 */
function processNewsList(data, teamName, slug) {
    const items = _get(data, 'items', []);

    return items.filter((item) => {
        return (
            item.type === 'story' &&
            !item.slug.includes(slug) &&
            item.tags.filter((tag) => tag.slug === teamName).length
        );
    });
}

function processNavItems(tabs) {
    let navItems = [];
    let socials = [];

    tabs.forEach((tab) => {
        const tabText = _isString(tab.text) && tab.text.toLowerCase().trim();
        VALID_SOCIALS[tabText] ? socials.push(tab) : navItems.push(tab);
    });

    return {
        tabs: navItems,
        socialIcons: processSocials(socials)
    };
}

function processSocials(socials) {
    const transformedArray = socials.map((social) => {
        const isSubscribe = social.text.toLowerCase().trim() === 'subscribe';
        const socialText = isSubscribe ? 'envelope-o' : social.text; // TODO: Social map
        const title = isSubscribe ? 'Sign up for YES Network emails' : `Follow YES Network on ${social.text}`; //TODO: this should come from feen eventually

        return {
            name: socialText,
            url: _get(social, 'properties.link'),
            newWindow: _get(social, 'properties.target'),
            title
        };
    });

    return transformedArray;
}

const createThumbnail = (thumbnailUrl) => thumbnailUrl.replace('{formatInstructions}', 't_1x1/w_60');

const createPromoSection = (contentArray) => {
    if (contentArray.length) {
        const coreItem = contentArray[0] ?? {};
        const { fields = {} } = coreItem;
        const { urlText: title, url, mobileUrl, isPopup = false } = fields;
        let homeLogo = '';
        if (contentArray.length > 1) {
            const item = contentArray[1]?.image ?? {};
            homeLogo = createThumbnail(item.templateUrl);
        }
        let awayLogo = '';
        if (contentArray.length > 2) {
            const item = contentArray[2]?.image ?? {};
            awayLogo = createThumbnail(item.templateUrl);
        }
        return {
            title,
            url,
            mobileUrl,
            isPopup,
            homeLogo,
            awayLogo,
        };
    } else {
        return undefined;
    }
};

const selectionItemSize = 3;
const createPromoSectionList = (selectionList) => {
    const sections = [];
    for (let i = 0; i < selectionList.length; i += selectionItemSize) {
        const contentArray = selectionList.slice(i, i + selectionItemSize);
        const section = createPromoSection(contentArray);
        if (!!section) {
            sections.push(section);
        }
    }
    return sections;
};

function processPromoBarData(todayOnYesData, nextGamesData) {
    const todayOnYes = createPromoSectionList(todayOnYesData.items);
    const nextGames = createPromoSectionList(nextGamesData.items);
    return {
        todayOnYes,
        nextGames,
    };
}

function isValidUrl(url) {
    try {
        new URL(url);
    } catch (_) {
        return false;
    }

    return true;
}

export {
    getImagePath,
    getShareImage,
    generateCuts,
    isValidUrl,
    parseCutsObj,
    transformImage,
    processPromoBarData,
    processNewsList,
    processNavItems
};
