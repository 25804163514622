import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DropdownItem, isValidUrl } from '../../index';
import './styles.less';

const MAX_PER_COL = 6; // Max number of items per col as defined by stakeholders
const MAX_COLS = 2; // Max number of cols as defined by designs

class Dropdown extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    splitDropdownItems(dropdownItems) {
        let subsetArray = [];
        for (let i = 0; i < dropdownItems.length; i += MAX_PER_COL) {
            const subset = dropdownItems.slice(i, i + MAX_PER_COL);
            subsetArray.push(subset);
        }
        subsetArray = subsetArray.slice(0, MAX_COLS);
        return subsetArray;
    }

    renderDropdownItems(className, dropdownItems) {
        const { baseURL } = this.props;
        return this.splitDropdownItems(dropdownItems).map((itemSubset, key) => {
            return (
                <div className={`${className}__col`} key={key}>
                    {itemSubset.map((item, itemKey) => {
                        const { link, target } = item.properties;
                        const dropdownLink = isValidUrl(link) ? link : `${baseURL}${link}`;
                        return (
                            <DropdownItem
                                className={className}
                                url={dropdownLink}
                                target={target}
                                text={item.text}
                                key={itemKey}
                            />
                        );
                    })}
                </div>
            );
        });
    }

    render() {
        const { className, dropdownItems, show } = this.props;
        return show === true ? (
            <div className={`${className}`}>{this.renderDropdownItems(className, dropdownItems)}</div>
        ) : null;
    }
}

Dropdown.propTypes = {
    baseURL: PropTypes.string,
    className: PropTypes.string,
    dropdownItems: PropTypes.array,
    show: PropTypes.bool
};

Dropdown.defaultProps = {
    baseURL: 'https://www.yesnetwork.com',
    className: 'dropdown',
    dropdownItems: [],
    show: true
};

export default Dropdown;
