import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './styles.less';

class BaseModule extends Component {
    constructor(props) {
        super(props);
        this.props = props;
    }

    render() {
        const { extraClass, headerContent, bodyContent, footerContent } = this.props;

        return (
            <div className={classNames('module', extraClass)}>
                {headerContent && <div className="module__header">{headerContent}</div>}
                {bodyContent && <div className="module__body">{bodyContent}</div>}
                {footerContent && <div className="module__footer">{footerContent}</div>}
            </div>
        );
    }
}

BaseModule.propTypes = {
    extraClass: PropTypes.string,
    headerContent: PropTypes.object,
    bodyContent: PropTypes.object,
    footerContent: PropTypes.object
};

export default BaseModule;
