import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Ad, BannerText, Blurb, Link, StoryPreview, Tile, VideoPreview } from '../../index';
import './styles.less';

class MixedFeedItem extends Component {
    // eventually include ad component or other components
    renderMediaItem(mediaItem) {
        const { className } = this.props;
        switch (mediaItem.type) {
            case 'video':
                return <VideoPreview className={`${className}-video`} {...mediaItem} />;
            case 'story':
                return <StoryPreview className={`${className}-story`} {...mediaItem} />;
            case 'ad':
                return <Ad sectionName={mediaItem.sectionName} targeting={{ pos: 2 }} slotSize={[300, 250]} />;
            case 'tile':
                return <Tile className={`${className}-tile`} {...mediaItem} />;
            case 'bannertext':
                return <BannerText className={`${className}-banner`} {...mediaItem} />;
            case 'shortcontent':
                return <Tile className={`${className}-tile`} {...mediaItem} />;
            default:
                return null;
        }
    }

    buildTitle(mediaItem) {
        const { className } = this.props;
        const { hbContentId, headline, isPopup, slug, teamContext, title, topicId, url } = mediaItem;
        const target = isPopup ? '_blank' : '';

        switch (mediaItem.type) {
            case 'video':
                return (
                    <Link className={`${className}--headline link`} href={`/video/${hbContentId || slug}${topicId}`}>
                        {[title]}
                    </Link>
                );
            case 'shortcontent':
                return (
                    <Link className={`${className}--headline link`} href={url} target={target}>
                        {[title]}
                    </Link>
                );
            case 'story':
                return (
                    <Link
                        className={`${className}--headline link`}
                        href={`${teamContext.path || ''}/news/${slug}`}
                        title={title}
                    >
                        {[headline]}
                    </Link>
                );
            default:
                return <div className={`${className}--headline`}>{title}</div>;
        }
    }

    render() {
        const { className, mediaItem } = this.props;
        return (
            <div className={`${className} ${mediaItem.type}`}>
                {this.renderMediaItem(mediaItem)}
                {mediaItem.title && this.buildTitle(mediaItem)}
                {mediaItem.type === 'tile' && <Blurb className={className} {...mediaItem} />}
            </div>
        );
    }
}

export default MixedFeedItem;

MixedFeedItem.propTypes = {
    className: PropTypes.string,
    mediaItem: PropTypes.object,
    headline: PropTypes.string
};
