import React from 'react';
import PropTypes from 'prop-types';

const EMPTY = '';

class Link extends React.Component {
    render() {
        const props = this.props;
        const { text = EMPTY, icon, children, href, title, className, target, classNames = [], handlers = {} } = props;
        const newWindow = target === '_blank';

        return (
            <a
                className={className}
                href={href}
                title={title}
                target={target}
                rel={newWindow ? 'noopener noreferrer' : null}
            >
                {children ? children : <span className={`${className}--description`}>{text}</span>}
            </a>
        );
    }
}

Link.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    classNames: PropTypes.array,
    icon: PropTypes.object,
    handlers: PropTypes.object,
    href: PropTypes.string.isRequired,
    target: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string
};

export default Link;
